<template>
  <div>
    <el-row :gutter="10">
      <el-col :md="12" :lg="8" :xl="6">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
          v-if="model"
        >
          <el-form-item
            :label="fields.name.label"
            :prop="fields.name.name"
            :required="fields.name.required"
          >
            <el-col>
              <el-input v-model="model[fields.name.name]" />
            </el-col>
          </el-form-item>

          <el-form-item>
            <div class="form-buttons">
              <el-button
                :disabled="saveLoading"
                @click="doSubmit"
                icon="el-icon-fa-floppy-o"
                type="primary"
                v-if="!modal"
              >
                <app-i18n code="common.save"></app-i18n>
              </el-button>

              <el-button
                :disabled="saveLoading"
                @click="doPropose"
                icon="el-icon-fa-floppy-o"
                type="primary"
                v-if="modal"
              >
                <app-i18n code="common.propose"></app-i18n>
              </el-button>

              <el-button
                :disabled="saveLoading"
                @click="doCancel"
                icon="el-icon-fa-close"
              >
                <app-i18n code="common.cancel"></app-i18n>
              </el-button>
            </div>
          </el-form-item> </el-form
      ></el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { KostenstelleModel } from '@/modules/kostenstelle/kostenstelle-model';

const { fields } = KostenstelleModel;
const formSchema = new FormSchema([fields.id, fields.name]);

export default {
  name: 'app-kostenstelle-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      const data = await this.validateForm();
      return this.$emit('submit', data);
    },

    async validateForm() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      let { id, ...values } = formSchema.cast(this.model);

      return { id, values };
    },
  },
};
</script>

<style></style>
